<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-end">
      <button
        v-if="$store.state.accessLevel > 9"
        @click="$router.push('/users/crew/add')"
        class="add"
      >
        New
      </button>
    </div>
    <Table
      :items="lifecrew"
      :defaultColumn="0"
      css="200px 80px 100px 1fr 60px"
      v-on:rowSelected="$router.push('/users/detail/' + $event._id)"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['fullName'],
        },
        {
          name: 'Birthday',
          sort: 'date',
          formatType: 'date',
          format: 'DD.MM.YYYY',
          path: ['birthday'],
        },
        {
          name: 'Phone',
          sort: 'numeric',
          path: ['phoneNumber'],
          formatType: 'phone',
        },
        {
          name: 'Email',
          sort: 'alphabet',
          path: ['email'],
        },
        {
          name: 'Hours',
          sort: 'numeric',
          path: ['totalHours'],
        },
      ]"
    ></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      users: [],
    };
  },
  components: {
    Table,
  },
  computed: {
    lifecrew() {
      return this.users.filter((user) => user.accessLevel > 4);
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    refresh() {
      this.getUsers()
        .then((users) => {
          this.users = users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
